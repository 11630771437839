.price {
    overflow: hidden;
    background-image: url("/img/pricebg.webp");
    background-size: cover;
    padding: 10rem 0 0;

    @media (max-width: 768px) {
        padding: 6rem 0 0rem;
        background-image: url("/img/hero-bg-mobile.webp");
    }
}

.price__container {
    position: relative;
    padding-bottom: 13rem;

    @media (max-width: 768px) {
        padding-bottom: 0rem;
    }
}

.price__inner {
    @media (max-width: 768px) {
        margin-bottom: 4rem;
    }
}

.price__img {
    position: absolute;
    right: 2%;
    bottom: 0;
    width: 68.6rem;

    @media (max-width: 1300px) {
        right: -15%;
        max-width: 50rem;
    }

    @media (max-width: 768px) {
        max-width: 100%;
        position: static;
        margin-bottom: -1rem;
    }
}

.price__num {
    margin-bottom: 4rem;
    font-size: 6.4rem;
    font-weight: 700;
    color: #5745C3;

    @media (max-width: 768px) {
        font-size: 2.4rem;
    }
}

.price__num-big {
    font-size: 12.8rem;
    line-height: 0.7;

    @media (max-width: 768px) {
        font-size: 6.4rem;
    }
}

.price__desc {
    margin-bottom: 6rem;
    font-size: 2.4rem;
    font-weight: 700;

    @media (max-width: 768px) {
        margin-bottom: 4rem;
        font-size: 1.8rem;
    }
}