.table-block {
    padding: 12rem 0;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: 6rem 0;
    }
}

.table-block__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
        content: "";
        position: absolute;
        right: -20rem;
        bottom: -8rem;
        width: 15rem;
        height: 32rem;
        background-image: url("/img/rect3.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: -45rem;
        bottom: -25rem;
        width: 75rem;
        height: 75rem;
        background-image: url("/img/rect2.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        animation: rotate 15s infinite linear;

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.table-block__table {
    position: relative;
    margin-bottom: 4rem;
    border: 1px solid #fff;
    border-collapse: collapse;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: -1rem;
        right: -1rem;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
    }

    & tr:first-child td {
        text-transform: uppercase;
    }

    & td {
        border: 1px solid #fff;
        padding: 2.8rem 5rem;
        text-align: center;
        font-weight: 700;
        font-style: italic;
        font-size: 2.4rem;
        background-color: $darkviolet;

        @media (max-width: 1200px) {
            padding: 1rem 2rem;
            font-size: 2rem;
        }

        @media (max-width: 768px) {
            padding: .5rem 1rem;
            font-size: 1rem;
        }
    }
}

.table-block__desc {
    margin-bottom: 6rem;
    font-weight: 700;
    font-size: 2.4rem;

    @media (max-width: 1200px) {
        font-size: 2rem;
    }

    @media (max-width: 768px) {
        margin-bottom: 3rem;
        font-size: 1rem;
    }
}