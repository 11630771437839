.reviews {
    margin-bottom: 12rem;

    @media (max-width: 768px) {
        margin-bottom: 6rem;
    }
}

.reviews__item {
    height: 36rem;
    overflow: hidden;


    @media (max-width: 1600px) {
        height: 34rem;
    }

    @media (max-width: 368px) {
        height: 28rem;
    }
}

.reviews__img {
    height: 100%;
    object-fit: cover;
}

.reviews__btn {
    display: inline-flex;
    width: 100%;
    max-width: 34rem;
    position: relative;
    cursor: pointer;
    transition: all .3s;

    &:before {
        content: "";
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        transform: skew(-15deg);
        transition: all .3s;
        z-index: 1;
        border: 1px solid #FFBA33;
        background-color: $background;
    }

    &:after {
        content: "";
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        z-index: 0;
        border: 1px solid #fff;
        transform: skew(-15deg) translate(.5em, -.5em);
    }

    &:hover {
        &:before {
            transform: skew(-15deg) translate(.3em, -.3em);
        }

        & .reviews__btn-text {
            transform: translate(.2em, -.2em);
        }
    }
}

.reviews__btn-text {
    position: relative;
    z-index: 1;
    display: inline-flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
    padding: 1rem 2.5rem 1rem 4.5rem;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #FFBA33;
    transition: all .3s;
}

.reviews__btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    background-color: #F9A400;
    border-radius: 50%;
}

.reviews__btn-subtext {
    text-align: left;
}

.reviews__btn-img {
    width: 1rem;
}

.reviews__owl {
    position: relative;
    margin-bottom: 6rem;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        right: 0;
        transform: translateX(50%);
        top: -8rem;
        width: 24rem;
        height: 20rem;
        background-image: url("/img/rect1.svg");
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            display: none;
        }
    }

    @media (max-width: 1660px) {
        padding: 0 1rem;
    }

    @media (max-width: 768px) {
        margin-bottom: 4rem;
    }


}

.reviews__btn-row {
    display: flex;
    justify-content: center;
}

.reviews .owl-prev {
    position: absolute;
    top: 50%;
    left: -5rem;
    width: 2rem;
    height: 4rem;
    transform: translateY(-50%) rotate(-180deg);
    background-color: transparent !important;
    background-image: url("/img/arrow.svg") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    @media (max-width: 1660px) {
        left: -2rem;
        width: 1.5rem;
        height: 2rem;
    }

    & span {
        display: none;
    }
}

.reviews .owl-next {
    position: absolute;
    top: 50%;
    right: -5rem;
    width: 2rem;
    height: 4rem;
    transform: translateY(-50%);
    background-color: transparent !important;
    background-image: url("/img/arrow.svg") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    @media (max-width: 1660px) {
        right: -2rem;
        width: 1.5rem;
        height: 2rem;
    }

    & span {
        display: none;
    }
}