.modal {
    position: fixed !important;
    left: -5000px;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;
    transition: opacity .3s;
    background-color: $background;
    color: #fff;
    z-index: 9999999;
}

.modal.active {
    left: 0;
    opacity: 1;
}

.beauty {
    outline: none;
    background-color: $background !important;
}

.beauty:-internal-autofill-selected {
    background-color: transparent !important;
}

.beauty::placeholder {
    color: #fff;
}

.modal__top {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    gap: 2rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 0rem;
        margin-bottom: 2rem;
    }
}

.modal__inner {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 6rem;
    padding-right: 6rem;

    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 10%;
        transform: translateX(80%);
        width: 75rem;
        height: 75rem;
        background-image: url("/img/rect2.svg");

        @media (max-width: 1024px) {
            display: none;
        }
    }

    @media (max-width: 768px) {
        padding-top: 5rem;
        padding-bottom: 2rem;
        padding-right: 2rem;
    }
}

.modal-title {}

.modal__desc {
    margin-top: 2rem;
    font-size: 3.6rem;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    text-align: right;

    @media (max-width: 1600px) {
        margin-top: 0;
        font-size: 2.5rem;
    }

    @media (max-width: 1600px) {
        font-size: 1.8rem;
        text-align: left;
    }
}

.modal__map {
    height: 29rem;
    width: 100%;
    max-width: 65rem;
    overflow: hidden;

    @media (max-width: 1400px) {
        max-width: 40rem;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.footer__top-address-list_modal {
    margin-top: 4rem;
    font-size: 2.4rem;
}

.modal__middle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.modal__right {
    justify-self: end;
    width: 100%;
    max-width: 56rem;

    @media (max-width: 768px) {
        justify-self: start;
    }
}

.modal__btn {
    background-color: transparent;
    max-width: 54rem;
}

.input__wrap {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: -1rem;
        right: -1rem;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
    }
}

.beauty {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 5rem;
    padding: 1.9rem 1.8rem;
    font-weight: 600;
    background-color: $background;
    border: 1px solid #fff;
    color: #fff;

    @media (max-width: 768px) {
        margin-bottom: 3rem
    }
}

.modal__textarea {
    height: 6.5rem;
}

.modal__bottom {
    position: relative;
    margin-top: 6rem;
    padding: 4rem 0 6rem;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -100rem;
        right: -100rem;
        height: 1px;
        background-color: #D9D9D9;
    }
}

.modal__bottom-text {
    margin-bottom: 2rem;
    color: #F9A400;
    font-size: 3.6rem;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 3rem;
    }
}

.modal__bottoom-list {
    max-width: 120rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3rem;
}

.modal__bottom-item {
    max-width: 36rem;
    display: flex;
    gap: 2rem;
    padding: 2rem 2rem 1.8rem;
    align-items: center;
    color: #F9A400;
    font-size: 2.2rem;
    font-weight: 700;
    background-color: #5745C3;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: -1rem;
        right: -1rem;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
    }
}

.modal__icon {
    width: 4rem;
}

.form-animate.ok {}