.btn {
    display: inline-block;
    width: 100%;
    max-width: 37rem;
    position: relative;
    cursor: pointer;
    transition: all .3s;

    &:before {
        content: "";
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        background-color: #F9B300;
        transform: skew(-15deg);
        transition: all .3s;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        z-index: 0;
        border: 1px solid #fff;
        transform: skew(-15deg) translate(.5em, -.5em);
    }

    &:hover {
        &:before {
            transform: skew(-15deg) translate(.3em, -.3em);
        }

        & .btn__text {
            transform: translate(.2em, -.2em);
        }
    }
}

.btn__text {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    padding: 1.8rem;
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #1E1E1E;
    transition: all .3s;
}