@font-face {
  font-family: "gilroy";
  src: url("/fonts/Gilroy-Regular.woff2") format("woff2"),
    url('/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gilroy";
  src: url("/fonts/Gilroy-Medium.woff2") format("woff2"),
    url('/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gilroy";
  src: url("/fonts/Gilroy-Semibold.woff2") format("woff2"),
    url('/fonts/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gilroy";
  src: url("/fonts/Gilroy-Bold.woff2") format("woff2"),
    url('/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gilroy";
  src: url("/fonts/Gilroy-BoldItalic.woff2") format("woff2"),
    url('/fonts/Gilroy-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}