.slider__item {
    height: 54rem;
}

.slider__top,
.slider__bottom {
    height: 50%;
    overflow: hidden;
}

.slider__img {
    height: 100%;
    object-fit: cover;
}

.slider__owl {
    position: relative;
    margin-bottom: 6rem;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        transform: translateX(-50%);
        bottom: -5rem;
        width: 24rem;
        height: 20rem;
        background-image: url("/img/rect1.svg");
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            display: none;
        }
    }

    @media (max-width: 1660px) {
        padding: 0 1rem;
    }
}

.slider .owl-prev {
    position: absolute;
    top: 50%;
    left: -5rem;
    width: 2rem;
    height: 4rem;
    transform: translateY(-50%) rotate(-180deg);
    background-color: transparent !important;
    background-image: url("/img/arrow.svg") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    @media (max-width: 1660px) {
        left: -2rem;
        width: 1.5rem;
        height: 2rem;
    }

    & span {
        display: none;
    }
}

.slider .owl-next {
    position: absolute;
    top: 50%;
    right: -5rem;
    width: 2rem;
    height: 4rem;
    transform: translateY(-50%);
    background-color: transparent !important;
    background-image: url("/img/arrow.svg") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    @media (max-width: 1660px) {
        right: -2rem;
        width: 1.5rem;
        height: 2rem;
    }

    & span {
        display: none;
    }
}

.slider {
    margin-bottom: 12rem;

    @media (max-width: 768px) {
        display: none;
    }
}

.slider-mobile {
    display: none;
    margin-bottom: 6rem;

    @media (max-width: 768px) {
        display: block;
    }
}

.slider-mobile__item {
    height: 40rem;
}

.slider-mobile__img {
    height: 100%;
    object-fit: cover;
}

.slider-mobile__owl {
    position: relative;
    margin-bottom: 6rem;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        transform: translateX(-50%);
        bottom: -5rem;
        width: 24rem;
        height: 20rem;
        background-image: url("/img/rect1.svg");
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            display: none;
        }
    }

    @media (max-width: 1660px) {
        padding: 0 1rem;
    }
}

.slider-mobile .owl-prev {
    position: absolute;
    top: 50%;
    left: -5rem;
    width: 2rem;
    height: 4rem;
    transform: translateY(-50%) rotate(-180deg);
    background-color: transparent !important;
    background-image: url("/img/arrow.svg") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    @media (max-width: 1660px) {
        left: -2rem;
        width: 1.5rem;
        height: 2rem;
    }

    & span {
        display: none;
    }
}

.slider-mobile .owl-next {
    position: absolute;
    top: 50%;
    right: -5rem;
    width: 2rem;
    height: 4rem;
    transform: translateY(-50%);
    background-color: transparent !important;
    background-image: url("/img/arrow.svg") !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    @media (max-width: 1660px) {
        right: -2rem;
        width: 1.5rem;
        height: 2rem;
    }

    & span {
        display: none;
    }
}