.has-youtube .fancybox__content,
.has-vimeo .fancybox__content,
.has-html5video .fancybox__content {
    @media (max-width: $mintablet) {
        width: 96vw !important;
    }
}

.modal-games {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: -500rem;
    opacity: 0;
    z-index: -1;
    background: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
}

.modal-games.open {
    opacity: 1;
    z-index: 1000;
    left: 0;
}

.modal-games__text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    opacity: 0;
    transform: translate(-50%, -60%);
    transition: all .3s ease;
    visibility: hidden;
    background-color: #030434;
    padding: 6rem 6rem 14rem 20rem;
    font-size: 1.8rem;
    font-weight: 400;

    @media (max-width: 1280px) {
        padding: 4rem 4rem 4rem 4rem;
    }

    @media (max-width: $mobile) {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100vh;
        font-size: 1.6rem;
    }
}

.modal-games__inner {
    max-width: 114.5rem;
    font-weight: 700;
}

.modal-games.open .modal-games__text {
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: visible;
}

.modal-games__close {
    position: absolute;
    top: 0rem;
    right: 0rem;
    padding: 3.5rem;

    @media (max-width: 1280px) {
        padding: 1.5rem;
    }
}

.modal-games__close-icon {
    width: 4.1rem;
    height: 4.1rem;
    cursor: pointer;

    @media (max-width: 1280px) {
        width: 2rem;
        height: 2rem;
    }
}

.modal-games__bottom {
    margin-bottom: 6rem;
    color: #F9A400;
}

.modal-games__after {
    color: #F9A400;
}

.modal-games__title {
    @media (max-width: 1450px) {
        font-size: 4rem;
    }

    @media (max-width: $tablet) {
        margin-bottom: 4.2rem;
        font-size: 2rem;
    }
}

.modal-games__title .title__colored {
    @media (max-width: 480px) {
        display: inline-block;
        width: max-content;
    }
}