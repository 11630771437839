.footer {
    overflow: hidden;
}

.footer__logo {
    display: block;
    margin-bottom: 4rem;

    @media (max-width: 768px) {
        margin-bottom: 2rem;
    }
}

.footer__logo-img {
    width: 23rem;

    @media (max-width: 768px) {
        width: 12rem;
    }
}

.footer__mail-row {
    margin-bottom: 6rem;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 768px) {
        margin-bottom: 2rem;
    }

    & a {
        text-decoration: underline;
        color: #F9A400;
    }
}

.footer__socials-list {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;

    & img {
        width: 3rem;
    }
}

.footer__top {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 4rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.footer__top-right-two,
.footer__top-right {
    margin-top: 12rem;

    @media (max-width: 768px) {
        margin-top: 0;
    }
}

.footer__top-subtitle {
    margin-bottom: 2rem;
    color: #F9A400;
    font-size: 1.6rem;
    font-weight: 700;
    font-style: italic;
}

.footer__top-list {
    display: grid;
    gap: 2rem;

    @media (max-width: 768px) {
        gap: 1rem;
    }
}

.footer__top-link {
    font-size: 1.6rem;
}

.footer__top-address-list {
    display: grid;
    gap: 2rem;
    font-size: 1.6rem;
    font-weight: 600;

    @media (max-width: 768px) {
        gap: 1rem;
    }
}

.footer__top-address-colored {
    color: #F9A400;
}

.footer__bottom {
    position: relative;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 4rem 0;

    @media (max-width: 768px) {
        padding: 2rem 0;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -100rem;
        right: -100px;
        height: 1px;
        background-color: #D9D9D9;
    }


    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

.footer__bottom-right a {
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 4px;
}