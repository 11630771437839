.tariff__mtitle {
    margin-top: 4rem;
}

.tariff__mtitle .title__colored {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: -.1em;
        right: -.1em;
        top: 0;
        bottom: -.1em;
        background-color: #F9A400;
        z-index: -1;
        transform: skew(-15deg);

        @media (max-width: $tablet) {
            top: -.2rem;
            bottom: -.2em;
            left: -.2em;
            right: -.2em;
        }
    }
}

.reviews__btn_birthday {

    &:before {
        content: "";
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        transform: skew(-15deg);
        transition: all .3s;
        z-index: 1;
        border: 1px solid #fff;
        background-color: #fff;
    }
}

.tariff__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2.4rem;
    margin-bottom: 12rem;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-bottom: 6rem;
    }
}

.tariff__item {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #5745C3;
}

.tariff__text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0rem 2rem 4rem;
    color: #fff;
    font-weight: 600;
}

.tariff__info {
    flex-grow: 1;
    margin-bottom: 4rem;

    & ul {
        margin-bottom: 4rem;
    }
}

.tariff__image-block {
    height: 52rem;
    overflow: hidden;
}

.tariff__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.tariff__title {
    margin-bottom: 2rem;
    font-size: 3.2rem;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

.tariff__table {
    margin-bottom: 4rem;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #fff;
}

.tariff__td {
    border: 1px solid #fff;
    padding: 1rem 2rem;
}

.point__item {
    position: relative;
    padding-left: 2rem;
}

.point__list .point__item {
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: .35em;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: #fff;
    }
}

.triangle__list .point__item {
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: .35em;
        width: 1rem;
        height: 1rem;
        background-image: url("/img/birt/tri.svg");
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.gift__item-img {
    position: relative;
    margin-bottom: -.4rem;
    margin-right: 1rem;
}

.tariff__container {
    position: relative;
}

.sub1 {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 4rem;
    width: 30rem;
    height: 30rem;
    background-image: url("/img/birt/gift.webp");
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1100px) {
        display: none;
    }
}

.sub2 {
    position: absolute;
    z-index: -1;
    left: -55rem;
    top: 40%;
    width: 72rem;
    height: 72rem;
    background-image: url("/img/birt/heart.webp");
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1100px) {
        display: none;
    }
}

.sub3 {
    position: absolute;
    z-index: -1;
    right: -25rem;
    bottom: 0;
    width: 30rem;
    height: 30rem;
    background-image: url("/img/birt/rocket.webp");
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1100px) {
        display: none;
    }
}