.events {
    margin-bottom: 12rem;

    @media (max-width: 1100px) {
        margin-bottom: 6rem;
    }
}

.events__container {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        transform: translateX(-50%);
        top: 25rem;
        width: 23rem;
        height: 20.8rem;
        background-image: url("/img/rect4.svg");
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        right: 0;
        transform: translateX(50%);
        top: 5rem;
        width: 21rem;
        height: 21rem;
        background-image: url("/img/rect5.svg");
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.events__wrap {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

.events__item:first-child {
    background-image: url("/img/events0.webp");
}

.events__item:nth-child(2) {
    background-image: url("/img/events.webp");
}

.events__item {
    position: relative;
    aspect-ratio: 1.2/1;
    background-size: cover;
    overflow: hidden;
}

.events__desc {
    position: absolute;
    left: 0;
    bottom: 0;

    &:before {
        content: "";
        position: absolute;
        left: -2rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        background-color: #F9B300;
        transform: skew(-15deg);
        transition: all .3s;

    }
}

.events__desc-text {
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 3.6rem 14rem 3.6rem 4rem;
    font-size: 3.2rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #1E1E1E;

    @media (max-width: 1150px) {
        padding: 1.6rem 4rem 1.4rem 2rem;
        font-size: 1.6rem;
    }
}