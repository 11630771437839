//MEDIA
$desktop: 1440px;
$tablet: 1024px;
$mintablet: 640px;
$mobile: 440px;


//COLORS
$background: #030434;
$white: #fff;
$violet: #5745C3;
$darkviolet: #26287C;

//FONTS
$gilroy: "gilroy", Arial, sans-serif;