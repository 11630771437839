.choose {
    overflow: hidden;
    margin-bottom: 7.5rem;
    padding-bottom: 10rem;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        padding-bottom: 5rem;
    }
}

.choose__list {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2.4rem;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

.choose__item {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 4rem;
    background-color: #26287C;
}

.choose__link {
    display: block;
    position: relative;
    width: 100%;
    height: 26rem;

    @media (max-width: 768px) {
        height: 24rem;
    }
}

.choose__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.choose__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6rem;

    @media (max-width: 768px) {
        width: 3.33rem;
    }
}

.choose__desc {
    flex-grow: 1;
    padding: 2rem 0 4rem;
}

.choose__subtitle {
    margin-bottom: 2rem;
    font-weight: 700;
    font-style: italic;
    font-size: 3.2rem;
    text-transform: uppercase;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 2rem;
    }
}

.choose__subitem {
    padding-left: 2rem;
    position: relative;
    font-weight: 600;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.7rem;
        width: 1rem;
        height: 1rem;
        background-image: url("/img/purplet.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.choose__more {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    color: #FFBA33;
    font-weight: 600;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }
}

.choose__first {
    position: absolute;
    z-index: -1;
    right: -7.5rem;
    top: -5rem;
    width: 26rem;
    height: 21rem;
    background-image: url("/img/rect1.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: move 7s infinite linear;

    @media (max-width: 768px) {
        right: -4.5rem;
        top: -3rem;
        width: 15rem;
        height: 12rem;
    }
}

.choose__second {
    position: absolute;
    z-index: -1;
    left: -9rem;
    bottom: -4rem;
    width: 26rem;
    height: 21rem;
    background-image: url("/img/rect1.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: move 5s infinite;

    @media (max-width: 768px) {
        left: -4rem;
        bottom: -1rem;
        width: 15rem;
        height: 12rem;
    }
}

@keyframes move {
    0% {
        transform: scale(1) translate(0px, 0px);
    }

    30% {
        transform: scale(1.1) translate(10px, 20px);
    }

    60% {
        transform: scale(1.2) translate(-10px, 20px);
    }

    100% {
        transform: scale(1) translate(0px, 0px);
    }
}

.choose__third {
    position: absolute;
    z-index: -1;
    right: -70rem;
    bottom: -10rem;
    width: 78rem;
    height: 78rem;
    background-image: url("/img/rect2.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: rotate 15s infinite linear;

    @media (max-width: 768px) {
        right: -50rem;
        bottom: 36%;
        width: 68rem;
        height: 68rem;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}