.tech {
    margin-bottom: 12rem;

    @media (max-width: 1100px) {
        margin-bottom: 6rem;
    }
}

.tech__container {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        transform: translateX(-70%);
        top: 28rem;
        width: 18rem;
        height: 20.6rem;
        background-image: url("/img/rect6.svg");
        background-size: contain;
        background-repeat: no-repeat;
        animation: pulse 5s infinite linear;

        @media (max-width: 768px) {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        right: -40rem;
        top: 20rem;
        width: 37rem;
        height: 37rem;
        background-image: url("/img/rect7.svg");
        background-size: contain;
        background-repeat: no-repeat;
        animation: rotate 40s infinite linear;

        @media (max-width: 768px) {
            display: none;
        }
    }
}

@keyframes pulse {
    0% {
        transform: translateX(-70%) scale(1);
    }

    50% {
        transform: translateX(-70%) scale(1.2);
    }

    100% {
        transform: translateX(-70%) scale(1);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.tech__list {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8.3rem;

    @media (max-width: 1200px) {
        gap: 3rem;
    }

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
}

.tech__item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4rem;
    padding: 2rem 4rem 4rem 4rem;
    background-color: #26287C;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: -1rem;
        right: -1rem;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
    }

    @media (max-width: 768px) {
        gap: 1rem;
        align-items: flex-start;
        padding: 1rem 2rem 2rem 1rem;
    }
}

.tech__icon {
    flex-shrink: 0;
    width: 12rem;

    @media (max-width: 1200px) {
        width: 4rem;
    }
}

.tech__subitem {
    margin-bottom: 2rem;
    font-size: 3.2rem;
    font-weight: 700;
    font-style: italic;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 2rem;
    }
}

.tech__text {
    font-weight: 500;

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
}