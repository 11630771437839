.birt {
    margin-bottom: 12rem;

    @media (max-width: 1100px) {
        margin-bottom: 6rem;
    }
}

.birt__title {
    text-align: center;
    font-size: 9.6rem;

    @media (max-width: 1100px) {
        font-size: 2.4rem;
    }
}

.birt__desc-row {
    margin-bottom: 7.6rem;
    display: flex;
    gap: 22rem;
    row-gap: 4rem;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        margin-bottom: 4rem;
    }
}

.birt__desc {
    max-width: 56.7rem;
    font-size: 2.4rem;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
}

.birt__btn {
    display: inline-block;
    width: 100%;
    max-width: 37rem;
    position: relative;
    cursor: pointer;
    transition: all .3s;

    &:before {
        content: "";
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        transform: skew(-15deg);
        transition: all .3s;
        z-index: 1;
        border: 1px solid #FFBA33;
        background-color: $background;
    }

    &:after {
        content: "";
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 0;
        bottom: 0;
        z-index: 0;
        border: 1px solid #fff;
        transform: skew(-15deg) translate(.5em, -.5em);
    }

    &:hover {
        &:before {
            transform: skew(-15deg) translate(.3em, -.3em);
        }

        & .birt__btn-text {
            transform: translate(.2em, -.2em);
        }
    }
}

.birt__btn-text {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    padding: 1rem 2.5rem;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    color: #FFBA33;
    transition: all .3s;
}

.birt__item {
    height: 48rem;
    width: auto;
    overflow: hidden;

    & img {
        height: 100%;
        object-fit: cover;
    }
}

.birt__owl {
    & .owl-dots {
        margin-top: 4rem !important;

        @media (max-width: 768px) {
            margin-top: 2rem !important;
        }
    }

    & .owl-dot.active span {
        background-color: #4944FF !important;
    }

    @media (max-width: 768px) {
        padding: 0 2rem;
    }
}