.title {
    margin-bottom: 6rem;
    font-size: 6.4rem;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
    line-height: 1.5;

    @media (max-width: $tablet) {
        margin-bottom: 4.2rem;
        font-size: 2.2rem;
    }
}

.title__colored {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: -.1em;
        right: -.1em;
        top: 0;
        bottom: -.1em;
        background-color: $violet;
        z-index: -1;
        transform: skew(-15deg);

        @media (max-width: $tablet) {
            top: -.2rem;
            bottom: -.2em;
            left: -.2em;
            right: -.2em;
        }
    }
}