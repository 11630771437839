.hero {
    position: relative;
    margin-bottom: 12rem;
}

.hero__video-wrap {
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
    height: 100%;
    overflow: hidden;

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: $tablet) {
        position: relative;
        height: 33rem;
    }
}

.hero__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-image: url("/files/video.webp");
    background-size: cover;
    background-position: center;


}

.hero__container {
    @media (max-width: $tablet) {
        background-image: url("/img/hero-bg-mobile.webp");
        background-size: cover;
        background-position: center;
    }
}

.hero__wrap {
    position: relative;
    z-index: 1;
    padding: 16rem 0 27rem;

    @media (max-width: $tablet) {
        padding: 4rem 0 8rem 0;
    }
}

.hero__title {
    margin-bottom: 4rem;
}

.hero__desc {
    margin-bottom: 6rem;
    max-width: 58.3rem;
    font-size: 2.4rem;

    @media (max-width: $tablet) {
        font-size: 1.6rem;
    }
}

.hero__back {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: -10%;
    width: 57%;
    height: 100%;
    background-image: url("/img/back.webp");
    background-position: left;
    background-size: cover;
    transform: skew(-15deg);

    @media (max-width: 1650px) {
        left: -5%;
    }

    @media (max-width: 1024px) {
        display: none
    }
}