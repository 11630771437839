.facts {
    overflow: hidden;
    margin-bottom: 12rem;

    @media (max-width: 768px) {
        margin-bottom: 6rem;
    }
}

.facts__container {
    position: relative;
    display: flex;
    flex-direction: column;

    &:after {
        content: "";
        position: absolute;
        left: -45rem;
        bottom: 0rem;
        width: 75rem;
        height: 75rem;
        background-image: url("/img/rect2.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
            left: -18rem;
            top: 4rem;
            bottom: initial;
            width: 30rem;
            height: 30rem;
        }
    }
}

.facts__list {
    align-self: flex-end;
    max-width: 50%;
    margin-right: 3rem;

    @media (max-width: 768px) {
        max-width: 100%;
        margin-right: 0;
    }
}

.facts__img {
    position: absolute;
    width: 361px;
    left: 12rem;
    top: 170px;
    transition: bottom .5s, top .5s;

    &.fixed {
        position: fixed;
        left: 12rem;
        top: 170px;
    }

    &.fixed-bottom {
        position: absolute;
        top: initial;
        bottom: 170px;
    }

    @media (max-width: 1024px) {
        left: 0rem;
    }

    @media (max-width: 768px) {
        position: static !important;
        width: 19.8rem;
        margin-bottom: 4rem;
    }
}

.facts__list {
    display: grid;
    gap: 6rem;

    @media (max-width: 768px) {
        margin-bottom: 2rem;
    }
}

.facts__item {
    position: relative;
    padding: 2rem 5.5rem 4rem;
    border: 1px solid #fff;
    background-color: #030434;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: -1rem;
        right: -1rem;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
    }

    @media (max-width: 768px) {
        padding: 2rem;
    }
}

.facts__subtitle {
    margin-bottom: 2rem;
    font-size: 4.2rem;
    font-weight: 700;
    font-style: italic;
    color: #F9A400;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 2.2rem;
    }
}

.facts__desc {
    font-weight: 500;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }
}