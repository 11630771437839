.header__container {
    @media (max-width: $tablet) {
        padding: 0 1.5rem 0 1rem;
    }
}

.header__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
}

.header__right {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.header__burger {
    display: none;
    line-height: 0;
    cursor: pointer;

    @media (max-width: $tablet) {
        display: block;
    }
}

.header__logo-img {
    width: 23.3rem;
    max-height: 7rem;

    @media (max-width: 1100px) {
        width: 16rem;
    }

    @media (max-width: $tablet) {
        width: 10rem;
    }
}

.header__menu {
    display: flex;

    @media (max-width: $tablet) {
        flex-direction: column;
        margin-top: 3rem;
    }
}

.header__menu__link {
    display: block;
    padding: 1.6rem;
    font-weight: 500;

    @media (max-width: $tablet) {
        padding: 1rem;
        text-align: center;
    }
}

.header__phone-top {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: .3rem;
}

.header__phone-icon-block {
    line-height: 0;
}

.header__phone-icon {
    width: 3rem;
    height: 3rem;
}

.header__phone-bottom {
    text-align: right;
}

.header__mobile {
    display: none;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 2rem;
    background-color: rgba(#030434, .8);

    @media (max-width: $tablet) {
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
        width: 100vw;
        transform: translateX(100%);
        transition: all .3s;
    }

    &.active {
        @media (max-width: $tablet) {
            transform: translateX(0);
        }
    }
}

.header__phone-link,
.header__phone-bottom {
    @media (max-width: $tablet) {
        display: none;
    }
}

.header__close {
    position: absolute;
    top: 2rem;
    right: 3rem;
}