/* Global */

:root {
  box-sizing: border-box;
  // --bg: rgb(255, 255, 0);
  // background-color: var(--bg);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.content-box-component {
  box-sizing: content-box;
}

html {
  height: 100%;
  width: 100%;
  font-family: $gilroy;
  font-size: 10px;
  line-height: 1.3;
  color: $white;
  font-weight: normal;
  background-color: $background;

  @media (min-width: 1921px) {
    font-size: .52vw;
  }
}

body {
  position: relative;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 400;
}

button,
input {
  outline: none;
  border: none;
  cursor: pointer;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

label {
  cursor: pointer;
}

// img {
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
//   flex-shrink: 0;
// }

.form-animate {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
  }

  &._sending {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &._ok {
    &:after {
      content: "Заявка отправлена! \A СВЯЖЕМСЯ С ВАМИ В ТЕЧЕНИИ ЧАСА.";
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: pre;
      opacity: 1;
      visibility: visible;
      color: rgb(203, 230, 203);
      text-transform: uppercase;
      background-color: rgba(#000, .7);
      background-image: none;
      font-weight: 600;
      text-align: center;
      padding: 30px;
    }
  }

  &._no {
    &:after {
      content: "Произошла ошибка, повторите пожалуйста !";
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      visibility: visible;
      color: rgb(228, 178, 178);
      text-transform: uppercase;
      background-image: none;
      font-weight: 600;
      text-align: center;
      padding: 30px;
      background-color: rgba(#000, .7);
    }
  }
}

input._error {
  transition: all .3s;
  outline: 2px solid rgba(red, .7);
}

textarea {
  resize: none;
}

.not {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  width: 100%;
  overflow: hidden;

  & img {
    margin-bottom: 5rem;
  }

  &:before {
    content: "";
    position: absolute;
    width: 75rem;
    height: 75rem;
    left: 0;
    transform: translateX(-50%) rotate(30deg);
    background-image: url("/img/rect2.svg");
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 40%;
    width: 75rem;
    height: 75rem;
    right: 0;
    transform: translateX(50%);
    background-image: url("/img/rect2.svg");
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 1200px) {
      display: none;
    }
  }
}

.not__title {
  text-align: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.page {
  flex-grow: 1;
}

.container {
  margin: 0 auto;
  //outline: 3px solid rgba(lightgreen, .2);
  outline-offset: -3px;
  max-width: 152rem;

  @media (max-width: 1580px) {
    padding: 0 3rem;
  }

  @media (max-width: $mobile) {
    padding: 0 2rem;
  }
}

._error {
  &:after {
    content: "Заполните пожалуйста поле";
    padding: .5rem;
    color: rgba(red, .7);
    font-size: 1.5rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

// Если в браузере отключен javascript
.no-js {
  & .wrapper::before {
    content: "Пожалуйста, разрешите использовать JavaScript! Без него сайт грустит...";
    position: fixed;
    top: -300px;
    left: 0;
    width: 100%;
    padding: 20px;
    z-index: 99999;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(241, 83, 94);
    background-color: rgba(0, 0, 0, 0.75);
    animation-name: real;
    animation-delay: 30s;
    animation-duration: 7s;
    animation-fill-mode: forwards;
  }
}

@keyframes real {
  0% {
    top: -500px;
    opacity: 0;
  }

  1% {
    top: 0;
    opacity: 1;
  }

  99% {
    top: 0;
    opacity: 1;
  }

  100% {
    top: -500px;
    opacity: 0;
  }
}